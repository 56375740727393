<template>
    <div :style="cssVars">
        <div class="settingsIcon" v-if="!settingsOpened" @click.stop="toggleSettings" :class="darkMode? 'dark-mode':''">
            <i class="fas fa-cog"></i>
        </div>
        <div class="settingsWindow" v-if="settingsOpened" @click.stop>
            <div class="settingsWindowContent" :class="darkMode? 'dark-mode':''">
                <div class="settingsWindowContentInner">
                    <div class="settingsWindowContentInnerSection">
                        <!-- cross to close -->
                        <button class="settingsCloseButton" @click="toggleSettings">x</button>
                        <div class="settingsWindowContentInnerSectionItem">
                            <label style="margin-right:1rem;" for="amount-of-cubes">Amount of cubes:</label>
                            <div style="margin-top:-0.5rem;display:flex; flex-direction: column; float:right;width:50%;">
                                <input class="form-control" type="number" max="300" min="0" step="10" style="width:50%;align-self:end;padding:0px;" v-model="amountOfCubesLocal" />
                                <input id="amount-of-cubes" type="range" min="0" step="10"  max="300" v-model="amountOfCubesLocal" class="slider" />
                            </div>
                        </div>
                        <div class="settingsWindowContentInnerSectionItem">
                            <label style="margin-right:1rem;" for="fov">Perspective value: </label>
                            <div style="margin-top:-0.5rem;display:flex; flex-direction: column; float:right;width:50%;">
                                <input class="form-control" type="number" style="width:50%;align-self:end;padding:0px;" step="10"  v-model="perspectiveLocal" />
                                <input id="fov" type="range" min="10" max="1000" step="10" v-model="perspectiveLocal" class="slider" />
                            </div>
                        </div>
                        <div class="settingsWindowContentInnerSectionItem">
                            <label style="margin-right:1rem;" for="fov">Render distance: </label>
                            <div style="margin-top:-0.5rem;display:flex; flex-direction: column; float:right;width:50%;">
                                <input class="form-control" type="number" style="width:50%;align-self:end;padding:0px;" step="1000"  v-model="renderDistanceLocal" />
                                <input id="render-distance" type="range" min="1000" max="30000" step="1000" v-model="renderDistanceLocal" class="slider" />
                            </div>
                        </div>
                        <div class="settingsWindowContentInnerSectionItem">
                            <label style="margin-right:1rem;" for="fov">Max spawn distance: </label>
                            <div style="margin-top:-0.5rem;display:flex; flex-direction: column; float:right;width:50%;">
                                <input class="form-control" type="number" style="width:50%;align-self:end;padding:0px;" step="1000"  v-model="spawnDistanceLocal" />
                                <input id="spawn-distance" type="range" min="3000" max="30000" step="1000" v-model="spawnDistanceLocal" class="slider" />
                            </div>
                        </div>
                        <div class="settingsWindowContentInnerSectionItem">
                            <label style="margin-right:1rem;" for="fov">Hue: </label>
                            <div style="margin-top:-0.5rem;display:flex; flex-direction: column; float:right;width:50%;">
                                <input class="form-control" type="number" style="width:50%;align-self:end;padding:0px;" v-model="hueLocal" />
                                <input id="hue-min" type="range" min="0" max="360" v-model="hueLocal" class="slider" />
                            </div>
                        </div>
                        <div class="settingsWindowContentInnerSectionItem">
                            <label style="margin-right:1rem;" for="fov">Hue offset: </label>
                            <div style="margin-top:-0.5rem;display:flex; flex-direction: column; float:right;width:50%;">
                                <input class="form-control" type="number" style="width:50%;align-self:end;padding:0px;"  v-model="hueOffsetLocal" />
                                <input id="hue-max" type="range" min="10" max="360" v-model="hueOffsetLocal" class="slider" />
                            </div>
                        </div>
                        <div class="settingsWindowContentInnerSectionItem">
                            <label style="margin-right:1rem;" for="fov">Saturation: </label>
                            <div style="margin-top:-0.5rem;display:flex; flex-direction: column; float:right;width:50%;">
                                <input class="form-control" type="number" style="width:50%;align-self:end;padding:0px;" v-model="saturationLocal" />
                                <input id="saturation" type="range" min="0" max="100" v-model="saturationLocal" class="slider" />
                            </div>
                        </div>
                        <div class="settingsWindowContentInnerSectionItem">
                            <label style="margin-right:1rem;" for="fov">Lightness: </label>
                            <div style="margin-top:-0.5rem;display:flex; flex-direction: column; float:right;width:50%;">
                                <input class="form-control" type="number" style="width:50%;align-self:end;padding:0px;" v-model="lightnessLocal" />
                                <input id="lightness" type="range" min="0" max="100" v-model="lightnessLocal" class="slider" />
                            </div>
                        </div>
                        <div class="settingsWindowContentInnerSectionItem">
                            <div class="form-check form-switch">
                                <label style="margin-right:1rem;" for="dark-mode">Dark mode</label>
                                <input class="form-check-input" type="checkbox" v-model="darkModeLocal" role="switch" id="flexSwitchCheckDefault">
                            </div>
                        </div>
                        <button class="reRenderButton" @click="reRender">Re-render</button>

<!--                        <div class="settingsWindowContentInnerSectionItem">-->
<!--                            <label for="settingsWindowContentInnerSectionItem">Setting 3</label>-->
<!--                            <input type="text" id="section0-setting2">-->
<!--                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Settings",
        props: ['amountOfCubes', 'perspective', 'renderDistance', 'spawnDistance', 'hue', 'hueOffset', 'saturation', 'lightness', 'darkMode'],
        data: function () {
            return {
                settingsOpened: false,
                amountOfCubesLocal: 0,
                perspectiveLocal: 0,
                renderDistanceLocal: 0,
                spawnDistanceLocal: 0,
                hueLocal: 0,
                hueOffsetLocal: 0,
                saturationLocal: 0,
                lightnessLocal: 0,
                darkModeLocal: false,
            }
        },
        computed: {
            cssVars() {
                return {
                }
            }
        },
        methods: {
            toggleSettings() {
                this.settingsOpened = !this.settingsOpened;
            },
            reRender() {
                this.$emit('reRender');
            },
            saveToStorage() {
                localStorage.setItem('amountOfCubes', this.amountOfCubesLocal);
                localStorage.setItem('perspective', this.perspectiveLocal);
                localStorage.setItem('renderDistance', this.renderDistanceLocal);
                localStorage.setItem('spawnDistance', this.spawnDistanceLocal);
                localStorage.setItem('hue', this.hueLocal);
                localStorage.setItem('hueOffset', this.hueOffsetLocal);
                localStorage.setItem('saturation', this.saturationLocal);
                localStorage.setItem('lightness', this.lightnessLocal);
                localStorage.setItem('darkMode', this.darkModeLocal);
                localStorage.setItem('settingsSaved', true);
                console.log('Settings saved');
            }
        },
        mounted() {
            if(localStorage.getItem('settingsSaved')) {
                this.amountOfCubesLocal = localStorage.getItem('amountOfCubes');
                this.perspectiveLocal = localStorage.getItem('perspective');
                this.renderDistanceLocal = localStorage.getItem('renderDistance');
                this.spawnDistanceLocal = localStorage.getItem('spawnDistance');
                this.hueLocal = localStorage.getItem('hue');
                this.hueOffsetLocal = localStorage.getItem('hueOffset');
                this.saturationLocal = localStorage.getItem('saturation');
                this.lightnessLocal = localStorage.getItem('lightness');
                this.darkModeLocal = localStorage.getItem('darkMode') === 'true';
            } else {
                this.amountOfCubesLocal = this.amountOfCubes;
                this.perspectiveLocal = this.perspective;
                this.renderDistanceLocal = this.renderDistance;
                this.spawnDistanceLocal = this.spawnDistance;
                this.hueLocal = this.hue;
                this.hueOffsetLocal = this.hueOffset;
                this.saturationLocal = this.saturation;
                this.lightnessLocal = this.lightness;
                this.darkModeLocal = this.darkMode;
            }

            if(window.mobileCheck()) {
                this.amountOfCubesLocal = Math.floor(this.amountOfCubesLocal * 0.5);
            }

            document.addEventListener('click', (e) => {
                if (this.settingsOpened && !e.target.closest('.settingsWindow')) {
                    this.settingsOpened = false;
                }
            })

            this.saveToStorage();
        },
        watch: {
            amountOfCubesLocal: function (val) {
                this.$emit('update:amountOfCubes', val);
                this.saveToStorage();
            },
            perspectiveLocal: function (val) {
                this.$emit('update:perspective', val);
                this.saveToStorage();
            },
            renderDistanceLocal: function (val) {
                this.$emit('update:maxZDist', val);
                this.saveToStorage();
            },
            spawnDistanceLocal: function (val) {
                this.$emit('update:spawnDistance', val);
                this.saveToStorage();
            },
            hueLocal: function (val) {
                this.$emit('update:hue', val);
                this.saveToStorage();
            },
            hueOffsetLocal: function (val) {
                this.$emit('update:hueOffset', val);
                this.saveToStorage();
            },
            saturationLocal: function (val) {
                this.$emit('update:saturation', val);
                this.saveToStorage();
            },
            lightnessLocal: function (val) {
                this.$emit('update:lightness', val);
                this.saveToStorage();
            },
            darkModeLocal: function (val) {
                this.$emit('update:darkMode', val);
                this.saveToStorage();
            }
        }
    }

</script>

<style scoped>
.settingsWindow {
    z-index: 99999;
    position: fixed;
    top: 1rem;
    right: 1rem;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}
.settingsWindowContent {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
}
.settingsWindowContent.dark-mode {
    background-color: rgba(40, 40, 40, 0.6);
    color:white;
}
.settingsWindowContent.dark-mode input:not([id="flexSwitchCheckDefault"]) {
    background-color: rgb(20, 20, 20);
    color:white;
}
.settingsWindowContent.dark-mode button {
    color:white !important;
}

/*
.settingsWindowContentInner {
}
.settingsWindowContentInnerSection {
}
*/
.settingsWindowContentInnerSectionItem {
    margin-bottom: 1.5rem;
}
.settingsIcon {
    z-index: 99999;
    position: fixed;
    width: 32px;
    height: 32px;
    line-height: 16px;
    top: 1rem;
    right: 1rem;
    color: #4f4f4f;
    background-color: rgba(255, 255, 255, 1);
    padding: 8px;
    border-radius: 50%;
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    transition: background-color 0.2s;
}
.settingsIcon.dark-mode {
    background-color: rgba(60, 60, 60, 1);
    color:white;
}
.settingsIcon:hover {
    background-color: rgba(255, 255, 255, 0.75);
    cursor: pointer;
}
.settingsIcon.dark-mode:hover {
    background-color: rgba(60, 60, 60, 0.75);
}
.settingsCloseButton {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    color: #4f4f4f;
    font-size: 1.5rem;
    line-height: 1rem;
    padding: 0.5rem;
    cursor: pointer;
}
.slider-value {
    font-size: 0.75rem;
    color: #4f4f4f;
    text-align: center;
}
.reRenderButton {
    background-color: #6f9aff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1rem;
}
.reRenderButton:hover {
    background-color: #4d70c3;
}
.reRenderButton:active {
    background-color: #223b75;
}

</style>