<template>
    <div class="atmos-cube shake-slow" v-if="visible" :style="cssVars">
        <div ref="topPane" v-if="posz * -1 < fallOffDist || !topHidden" class="pane top"
             :class="darkMode? 'dark-mode' : ''"></div>
        <div ref="bottomPane" v-if="posz * -1 < fallOffDist || !bottomHidden" class="pane bottom"
             :class="darkMode? 'dark-mode' : ''"></div>
        <div ref="leftPane" v-if="posz * -1 < fallOffDist || !leftHidden" class="pane left"
             :class="darkMode? 'dark-mode' : ''"></div>
        <div ref="rightPane" v-if="posz * -1 < fallOffDist || !rightHidden" class="pane right"
             :class="darkMode? 'dark-mode' : ''"></div>
        <div ref="frontPane" class="pane front" :class="darkMode? 'dark-mode' : ''"></div>
        <div ref="backPane" class="pane back"></div>
    </div>
</template>

<script>
import {ref} from "vue";

export default {
    name: "AtmosCube",
    props: ['posx', 'posy', 'posz', 'rotx', 'roty', 'rotz', 'rotAxis', 'colorBase', 'blurAmount', 'startZ', 'darkMode', 'transitionMode'],
    data: function () {
        return {
            visible: true,
            isActive: false,
            fallOffDist: 10000,
            closeFallOffDist: 1000,
            perspective: 0,
            topHidden: false,
            bottomHidden: false,
            leftHidden: false,
            rightHidden: false,
            paneDist: 100,
            rotationAxis: {
                x: 0,
                y: 0,
                z: 0
            },
            rotSpeed: 0.1,
            lightSource: {x: window.innerWidth / 5, y: window.innerHeight / 5},
        }
    },
    setup() {
        const topPane = ref('topPane')
        const bottomPane = ref('bottomPane')
        const leftPane = ref('leftPane')
        const rightPane = ref('rightPane')
        const frontPane = ref('frontPane')
        const backPane = ref('backPane')
        return {topPane, bottomPane, leftPane, rightPane, frontPane, backPane}
    },
    computed: {
        cssVars() {
            return {
                '--z-index': this.calculateOutput(this.posz * -1),
                //'--display': this.posz < this.closeFallOffDist ? 'block' : 'none',
                '--pos-X': this.posx + "vw",
                '--pos-Y': this.posy + "vh",
                '--pos-Z': this.posz + 'px',
                '--rot-X': this.rotx + "deg",
                '--rot-Y': this.roty + "deg",
                '--rot-Z': this.rotz + "deg",
                '--rand-anim-x': this.randInt() + "px",
                '--rand-anim-y': this.randInt() + "px",
                '--rand-anim-rot-x': (this.rotx + (this.randInt() * 0.1)) + "deg",
                '--rand-anim-rot-y': (this.roty + (this.randInt() * 0.1)) + "deg",
                '--rand-anim-rot-z': (this.rotz + (this.randInt() * 0.1)) + "deg",
                '--rand-time': this.randTime() + "s",
                '--color-base': 'rgb(' + this.colorBase[0] + ',' + this.colorBase[1] + ',' + this.colorBase[2] + ')',
                '--color-semi-dark': 'rgb(' + (this.colorBase[0] - 25) + ',' + (this.colorBase[1] - 25) + ',' + (this.colorBase[2] - 25) + ')',
                '--color-dark': 'rgb(' + (this.colorBase[0] - 60) + ',' + (this.colorBase[1] - 60) + ',' + (this.colorBase[2] - 60) + ')',
                '--color-light': 'rgb(' + (this.colorBase[0] + 70) + ',' + (this.colorBase[1] + 70) + ',' + (this.colorBase[2] + 70) + ')',
                //'--blur-amount': this.blurAmount * 30 + "px",
                '--outline': this.blurAmount > 0.2 ? 'none' : '3px solid black',
                '--opacity': this.blurAmount > 0.4 ? 1 - this.blurAmount : 1,
                '--transition-mode': this.transitionMode,
                '--paneDist': this.paneDist + 'px',
            }
        }
    },
    methods: {
        updateFaceLighting() {
            // const globalUp = { x: 0, y: 1, z: 0 };
            // let physicallyTop = null;
            // let bestDot = -Infinity;
            // for (const faceName of Object.keys(this.$refs)) {
            //     const normal = this.getTransformedNormal(faceName);
            //     const dot = this.dotProduct(normal, globalUp);
            //
            //     if (dot > bestDot) {
            //         bestDot = dot;
            //         physicallyTop = faceName;
            //     }
            // }
            //
            // // shade the top face
            // for (const faceName of Object.keys(this.$refs)) {
            //     const intensity = this.dotProduct(
            //         this.getTransformedNormal(faceName),
            //         this.getTransformedNormal(physicallyTop)
            //     );
            //     this.$refs[faceName].style.backgroundColor = this.getShadedColor(intensity);
            // }
        },

        getFacePosition(faceName) {
            // Return the position of the face in world coordinates
            const faceOffset = {
                frontPane: { x: 0, y: 0, z: this.paneDist },
                backPane: { x: 0, y: 0, z: -this.paneDist },
                topPane: { x: 0, y: this.paneDist, z: 0 },
                bottomPane: { x: 0, y: -this.paneDist, z: 0 },
                leftPane: { x: -this.paneDist, y: 0, z: 0 },
                rightPane: { x: this.paneDist, y: 0, z: 0 }
            };

            let posxPx = (this.posx / 100) * window.innerWidth;
            let posyPx = (this.posy / 100) * window.innerHeight;

            return {
                x: posxPx + faceOffset[faceName].x,
                y: posyPx + faceOffset[faceName].y,
                z: this.posz + faceOffset[faceName].z
            };
        },

        getShadedColor(intensity) {
            const lerp = (a, b, t) => a * (1 - t) + b * t;
            const base = this.colorBase;

            // Create gradient between dark and light colors
            const r = lerp(base[0] - 60, base[0] + 70, intensity);
            const g = lerp(base[1] - 60, base[1] + 70, intensity);
            const b = lerp(base[2] - 60, base[2] + 70, intensity);

            return `rgb(${Math.round(r)},${Math.round(g)},${Math.round(b)})`;
        },

        normalizeVector(v) {
            const length = Math.sqrt(v.x ** 2 + v.y ** 2 + v.z ** 2);
            return {
                x: v.x / length,
                y: v.y / length,
                z: v.z / length
            };
        },

        getTransformedNormal(faceName) {
            const normal = this.getNormalVector(faceName);
            const rotationMatrix = this.getRotationMatrix(
                this.rotx % 360,
                this.roty % 360,
                this.rotz % 360
            );

            return this.normalizeVector(
                this.applyMatrix(normal, rotationMatrix)
            );
        },

        getNormalVector(face) {
            switch (face) {
                case 'frontPane':
                    return { x: 0, y: 0, z: 1 };
                case 'backPane':
                    return { x: 0, y: 0, z: -1 };
                case 'topPane':
                    return { x: 0, y: 1, z: 0 };
                case 'bottomPane':
                    return { x: 0, y: -1, z: 0 };
                case 'leftPane':
                    return { x: -1, y: 0, z: 0 };
                case 'rightPane':
                    return { x: 1, y: 0, z: 0 };
                default:
                    return { x: 0, y: 0, z: 0 };
            }
        },

        applyMatrix(vector, matrix) {
            return {
                x: vector.x * matrix[0][0] + vector.y * matrix[0][1] + vector.z * matrix[0][2],
                y: vector.x * matrix[1][0] + vector.y * matrix[1][1] + vector.z * matrix[1][2],
                z: vector.x * matrix[2][0] + vector.y * matrix[2][1] + vector.z * matrix[2][2],
            };
        },

        dotProduct(v1, v2) {
            return v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
        },

        getRotationMatrix(rotx, roty, rotz) {
            const toRadians = (deg) => deg * (Math.PI / 180);

            const sinX = Math.sin(toRadians(rotx));
            const cosX = Math.cos(toRadians(rotx));
            const sinY = Math.sin(toRadians(roty));
            const cosY = Math.cos(toRadians(roty));
            const sinZ = Math.sin(toRadians(rotz));
            const cosZ = Math.cos(toRadians(rotz));

            // Rotation matrices for each axis
            const rotateX = [
                [1, 0, 0],
                [0, cosX, -sinX],
                [0, sinX, cosX],
            ];
            const rotateY = [
                [cosY, 0, sinY],
                [0, 1, 0],
                [-sinY, 0, cosY],
            ];
            const rotateZ = [
                [cosZ, -sinZ, 0],
                [sinZ, cosZ, 0],
                [0, 0, 1],
            ];

            // Combine rotations: Rz * Ry * Rx
            return this.multiplyMatrices(
                rotateZ,
                this.multiplyMatrices(rotateY, rotateX)
            );

        },

        multiplyMatrices(a, b) {
            const result = Array(3)
                .fill(0)
                .map(() => Array(3).fill(0));

            for (let i = 0; i < 3; i++) {
                for (let j = 0; j < 3; j++) {
                    result[i][j] =
                        a[i][0] * b[0][j] + a[i][1] * b[1][j] + a[i][2] * b[2][j];
                }
            }
            return result;
        },
        calculateOutput: function (x) {
            let min = this.$parent.$data.maxZDist * -1
            let max = 600
            let normalizedX = ((x - min) / (max - min)) * 100
            return Math.floor(200 / normalizedX * 1000);
        },
        randInt: function () {
            var flipInt = Math.random()
            if (flipInt > 0.5) {
                return Math.floor(Math.random() * 50) + 1
            } else {
                return -(Math.floor(Math.random() * 50) + 1)
            }
        },
        randTime: function () {
            return Math.floor(Math.random() * 20) + 5
        },
        observerCallback: function (entries, observer) {
            console.log(entries, observer)
        },
        setTransitionMode: function (mode) {
            this.transitionMode = mode
        },
        modifyScale: function (matrix, scaleX, scaleY, scaleZ) {
            // Split the matrix string into an array of numbers
            let values = matrix.slice(9, -1).split(',').map(Number);

            // Modify scale values for x, y, and z
            values[0] = scaleX; // scale x
            values[5] = scaleY; // scale y
            values[10] = scaleZ; // scale z

            console.log(values)

            // Reconstruct the matrix3d string
            return `matrix3d(${values.join(', ')})`;
        },
        dissapear: function (animationTime = 1) {
            // Apply scaling on top of the current transform
            // loop through refs
            for (const ref in this.$refs) {
                // eslint-disable-next-line no-prototype-builtins
                if (this.$refs.hasOwnProperty(ref)) {
                    const element = this.$refs[ref];

                    if (!element) continue;

                    element.style.transition = `opacity ${animationTime}s ease-out, transform 0.2s ease-out`;
                    element.style.opacity = 0;
                }
            }

            const element = this.$el;
            var compStyles = window.getComputedStyle(element);
            // element.transition = `transform ${animationTime}s ease-out`;
            // var matrix = compStyles.transform;
            // let newValue = 3; // Replace 100 with 50
            //
            // matrix = this.modifyScale(matrix, newValue, newValue, newValue);
            console.log(compStyles.transform)

            this.paneDist = 300;

            element.style.transform = compStyles.transform + ' scale3d(1.5, 1.5, 1.5)';

            // Timeout to reset styles and hide the element
            setTimeout(() => {
                this.visible = false;
                this.$el.transition = ''; // Reset to original transition mode if it's stored
                //this.$el.style.transform = ''; // Reset to original transform
            }, animationTime * 1000); // Make sure this matches the transition duration
        },
        updateVisibility: function (visible) {
            this.visible = visible
        }
        // isBehindOtherElement: function(element) {
        //     if (!element) return null;
        //     const boundingRect = element.getBoundingClientRect()
        //     const left = boundingRect.left + 1
        //     const right = boundingRect.right - 1
        //     const top = boundingRect.top + 1
        //     const bottom = boundingRect.bottom - 1
        //
        //     if(document.elementFromPoint(left, top) !== element) return true
        //     if(document.elementFromPoint(right, top) !== element) return true
        //     if(document.elementFromPoint(left, bottom) !== element) return true
        //     if(document.elementFromPoint(right, bottom) !== element) return true
        //
        //     return false
        // }
    },
    mounted() {
        // this.topHidden = this.isBehindOtherElement(this.$refs.topPane)
        // this.bottomHidden = this.isBehindOtherElement(this.$refs.bottomPane)
        // this.leftHidden = this.isBehindOtherElement(this.$refs.leftPane)
        // this.rightHidden = this.isBehindOtherElement(this.$refs.rightPane)

        var options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        }

        // eslint-disable-next-line no-unused-vars
        var observer = new IntersectionObserver(this.observerCallback, options)
    }
    // watch: {
    //     posz: function(val) {
    //         this.visible = (val < 500 || (this.blurAmount > 0.4 ? 1 - this.blurAmount : 1) > 0.01) && !this.visibleOverride
    //     },
    // }
}

</script>

<style scoped>
.atmos-cube {
    display: var(--display);
    z-index: var(--z-index);
    position: absolute;
    width: 200px;
    height: 200px;
    -webkit-transform-style: preserve-3d;
    transform-origin: center center 0;
    transform-style: preserve-3d;
    transform: translate3d(var(--pos-X), var(--pos-Y), var(--pos-Z)) rotateX(var(--rot-X)) rotateY(var(--rot-Y)) rotateZ(var(--rot-Z));
    transition: var(--transition-mode);
}

.pane {
    opacity: var(--opacity);
    z-index: 1;
    outline-offset: -3px;
    transform-style: preserve-3d;
    text-align: center;
    display: block;
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #2b7862;
    transition: all 0.5s;
    /*outline: var(--outline);*/
    /*box-shadow: 0 0 40px rgba(0, 0, 0, 0.25) inset;*/
}

.atmos-cube .pane.dark-mode {
    filter: brightness(0.8);
}

/*
    .shake-slow {
      animation-name:shake-slow;
      animation-duration: var(--rand-time);
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }


    @keyframes shake-slow {
      0%{
        transform:
            translate3d(
                var(--pos-X),
                var(--pos-Y),
                var(--pos-Z)
            )
            rotateX(var(--rot-X))
            rotateY(var(--rot-Y))
            rotateZ(var(--rot-Z))
      }
      50%{
        transform:
            translate3d(
                calc(var(--pos-X) - var(--rand-anim-x)),
                calc(var(--pos-Y) - var(--rand-anim-y)),
                var(--pos-Z)
            )
            rotateX(var(--rand-anim-rot-x))
            rotateY(var(--rand-anim-rot-y))
            rotateZ(var(--rand-anim-rot-z))
      }
      100%{
        transform:
            translate3d(
                var(--pos-X),
                var(--pos-Y),
                var(--pos-Z)
            )
            rotateX(var(--rot-X))
            rotateY(var(--rot-Y))
            rotateZ(var(--rot-Z))
      }
    }

    .front::after {
      content: '';
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background: linear-gradient(to bottom right, var(--color-light), var(--color-dark));
      opacity: 0.7;
    }

    .left::after {
      content: '';
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background: linear-gradient(to bottom, var(--color-light), var(--color-dark));
      opacity: 0.7;
    }*/

.front {
    -webkit-transform: translateZ(var(--paneDist));
    background-color: var(--color-base);
}

.back {
    -webkit-transform: rotateY(180deg) translateZ(var(--paneDist));
    background-color: var(--color-semi-dark);
}

.top {
    -webkit-transform: rotateX(90deg) translateZ(var(--paneDist));
    background-color: var(--color-light);
}

.bottom {
    -webkit-transform: rotateX(-90deg) translateZ(var(--paneDist));
    background-color: var(--color-dark);
}

.left {
    -webkit-transform: rotateY(-90deg) translateZ(var(--paneDist));
    background-color: var(--color-semi-dark);
}

.right {
    -webkit-transform: rotateY(90deg) translateZ(var(--paneDist));
    background-color: var(--color-semi-dark);
}
</style>